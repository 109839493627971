<template>
  <el-dialog
      v-model="isShow"
      title="新增项目"
      width="800px"
      :before-close="handleClose"
  >
    <el-form ref="formRef" :inline="false" :model="formState" :rules="rules" label-width="150px">
      <el-form-item label="所属区域" prop="areaId">
          <el-cascader
              v-model="formState.areaId"
              :options="areaOptions"
              :props="{value: 'orgId',label: 'orgName',children: 'subOrgList', checkStrictly: true,}"
              style="width:80%" @change="handleChangeArea"
              :disabled="areaDisabled"
          />
      </el-form-item>
      <el-form-item label="项目类型" prop="projectTypeId">
        <el-col :span="24">
          <el-select v-model="formState.projectTypeId" filterable clearable placeholder="请选择项目类型" @change="projectChange" style="width:80%">
            <el-option
                v-for="item in productTypeOptions"
                :key="item.id"
                :label="item.label"
                :value="item.id"
            />
          </el-select>
        </el-col>
      </el-form-item>
      <el-form-item label="项目名称" prop="name">
        <el-col :span="24">
          <el-input v-model="formState.name" disabled style="width:80%"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="节点模版" prop="nodeTemplateId">
        <el-select v-model="formState.nodeTemplateId" filterable clearable placeholder="请选择节点模版" style="width:80%">
          <el-option
              v-for="item in nodeTemplateOptions"
              :key="item.id"
              :label="item.label"
              :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="负责人" prop="userName" >
        <el-input v-model="formState.responsibleUserName" disabled style="width:80%"></el-input>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input type="textarea" v-model="formState.address" style="width:80%"></el-input>
      </el-form-item>
      <el-form-item label="项目调研" prop="surveyTemplateId">
        <el-select v-model="formState.surveyTemplateId" filterable clearable placeholder="请选择调研模版" @change="surveyTemplateChange" style="width:80%">
          <el-option
              v-for="item in surveyTemplateOptions"
              :key="item.id"
              :label="item.label"
              :value="item.id"
          />
        </el-select>
      </el-form-item>
      <survey-param ref="surveyParamRef" :survey-params="surveyParams" @complete="changeSurveyParam"></survey-param>
    </el-form>
    <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="handleSubmit" :disabled="submitting">
              确定
            </el-button>
          </span>
    </template>
  </el-dialog>
</template>

<script>
import {addProject, checkName} from '@/api/project'
import {isResOK, isSysResOK} from '@/api/response'
import {delProjectType, getProjectTypeOptions} from "@/api/project_type";
import {getNodeTemplateOptions} from "@/api/node_template";
import {getSurveyParams, getSurveyTemplateOptions} from "@/api/survey_template";
import {queryOrgRelTree} from "@/api/system";
import FileUpload from "@/components/FileUpload/index.vue";
import SurveyParam from "@/components/SurveyParam/index.vue";
import {traverse} from "@/utils/common";
import {areaOptions} from "@/api/mock";

export default {
  name: 'NewProject',
  components: {SurveyParam, FileUpload},
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['update:visible', 'completed'],

  data() {
    return {
      formState: {
      },
      rules: {
        areaId: [
          { required: true, message: '请选择所属区域', trigger: 'blur' },
        ],
        projectTypeId: [
          { required: true, message: '请选择项目类型', trigger: 'blur' },
        ],
        nodeTemplateId: [
          { required: true, message: '请选择节点模版', trigger: 'blur' },
        ],
        userName: [
          { required: false, message: '责任人不能为空', trigger: 'blur' },
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
          { min: 1, max: 200, message: '不能超过200字', trigger: 'blur' },
        ],
        surveyTemplateId: [
          { required: true, message: '请选择项目调研模版', trigger: 'blur' },
        ]
      },
      submitting: false,
      productTypeOptions: [],
      nodeTemplateOptions:[],
      surveyTemplateOptions: [],
      areaOptions:[],
      surveyParams: [],
      areaDisabled: true,
    }
  },

  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  created() {
    this.getProductTypeOptions()
    this.queryOrgRelTree()
    this.setUserInfo()
  },
  methods: {
    setUserInfo(){
     let userInfo = JSON.parse(localStorage.getItem('userInfo'));
     if (userInfo !== undefined){
       this.formState.responsibleUserName = userInfo.name
       this.formState.creatorName = userInfo.name
       this.formState.responsibleUserId = userInfo.id
       this.formState.creator = userInfo.id
     }
    },
    changeSurveyParam(surveyParamData){
      this.formState['surveyParamData'] = surveyParamData
    },
    getSurveyParams(surveyTemplateId){
      getSurveyParams(surveyTemplateId).then(res => {
        if (isResOK(res)){
          this.surveyParams = res.data
        }
      })
    },
    queryOrgRelTree(){
      this.areaDisabled = true
      queryOrgRelTree({
        "orgId": "1681542405985234945",
        "relType": "1"
      }).then(res => {
        if (isSysResOK(res)){
          this.areaOptions.push(res.result.orgRelInfoVo)
          this.areaDisabled= false
        }
      })
    },
    projectChange(val){
      this.surveyParams = []
      this.nodeTemplateOptions = []
      this.surveyTemplateOptions = []
      this.formState.nodeTemplateId = undefined
      this.formState.surveyTemplateId = undefined
      this.formState.name = undefined
      if (val > 0){
        this.getNodeTemplateOptions(val)
        this.getSurveyTemplateOptions(val)
        this.setProjectName()
      }
    },
    setProjectName(){
      this.formState.name = undefined
      if (this.formState.projectTypeId > 0 && this.formState.areaId != undefined){
        this.setAreaName()
        let name = this.formState.areaName
        this.productTypeOptions.forEach(item => {
          if (item.id === this.formState.projectTypeId){
            name += item.label
          }
        })
        this.formState.name = name
      }
    },
    setAreaName(){
      this.formState.areaName = undefined
      if (this.formState.areaId != undefined){
        let name = ''
        for (let i= 0; i< this.formState.areaId.length; i++){
          let orgId = this.formState.areaId[i]
          name += traverse(this.areaOptions,orgId)
        }
        this.formState.areaName = name
      }
    },
    surveyTemplateChange(val){
      this.surveyParams = []
      this.$refs.surveyParamRef.reset()
      if (val > 0){
        this.getSurveyParams(val)
      }
    },
    getNodeTemplateOptions(val){
      this.nodeTemplateOptions = []
      getNodeTemplateOptions(val).then(res =>{
        if (isResOK(res)) {
          this.nodeTemplateOptions = res.data
        }
      })
    },
    getSurveyTemplateOptions(val){
      this.surveyTemplateOptions = []
      getSurveyTemplateOptions(val).then(res =>{
        if (isResOK(res)) {
          this.surveyTemplateOptions = res.data
        }
      })
    },
    getProductTypeOptions(){
      getProjectTypeOptions().then(res =>{
        if (isResOK(res)) {
          this.productTypeOptions = res.data
        }
      })
    },
    handleChangeArea(val){
      this.setProjectName(val)
    },
    handleSubmit() {
      if (this.submitting) {
        this.$message({
          message: '已经提交，无需重复提交',
          type: 'warning',
        })
        return
      }

      this.submitting = true
      this.$refs.formRef.validate((valid, fields) => {
        if (valid) {
          checkName(Object.assign({}, this.formState)).then((res) =>{
            if (isResOK(res)){
              if (res.data.uniq === false){
                this.$messageBox.confirm(
                    '项目名已存在，确定创建吗？',
                    {
                      confirmButtonText: '确认',
                      cancelButtonText: '取消',
                      type: 'warning',
                    }
                ).then(() => {
                  this.insertProject()
                }).catch(() => {
                  this.submitting = false
                })
              }else{
                this.insertProject()
              }
            }
          })
        } else {
          this.submitting = false
        }
      })
    },
    insertProject(){
      addProject(Object.assign({}, this.formState, { operatingMode: 'web' })).then((res) => {
        if (isResOK(res)) {
          this.$message({
            message: '数据添加成功',
            type: 'success',
          })
          // 关闭模态框
          this.isShow = false
          // 告知父组件已完成
          this.$emit('completed')
        }
        this.submitting = false
      }).finally(() => {
        this.submitting = false
      })
    },
    handleClose() {
      this.isShow = false
    }
  },
}
</script>

import request from '@/utils/request'

// 查询节点模版列表
export function listNodeTemplate(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/node_template/list',
        method: 'get',
        params: query
    })
}

// 查询节点模版详细
export function getNodeTemplate(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/node_template/' + id,
        method: 'get'
    })
}

// 新增节点模版
export function addNodeTemplate(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/node_template',
        method: 'post',
        data: data
    })
}

// 修改节点模版
export function updateNodeTemplate(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/node_template',
        method: 'put',
        data: data
    })
}

// 删除节点模版
export function delNodeTemplate(id, data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/node_template/' + id,
        method: 'delete',
        data: data
    })
}

// 查询节点模版选项
export function getNodeTemplateOptions(projectTypeId) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/node_template/options?projectTypeId='+projectTypeId,
        method: 'get',
    })
}
